import React from "react";
import hero1 from "../../assets/hero1.png";
import hero2 from "../../assets/hero2.png";
import heroPolyLight from "../../assets/waves/heroPolyLight.svg";
import heroPolyDark from "../../assets/waves/heroPolyDark.svg";
import googleLogo from "../../assets/google-logo-transparent.png";
import "./TickerStyle.css";

const Hero = (props) => {
  return (
    <>
      {/* <section className="relative z-[2]">
				<div className="ticker-wrap">
					<div className="ticker">
						<div className="ticker_item">
						If you encounter login problems while using a browser other than Chrome, please attempt to log in using the Chrome browser instead. we are working on this issue.
						</div>
					</div>
				</div>
			</section> */}
      <div
        className="flex flex-col justify-center items-center px-6 md:px-[10%] md:flex-row-reverse md:justify-between dark:bg-dark h-screen md:pt-[60px] bg-no-repeat bg-center bg-cover"
        style={{
          backgroundImage:
            props.theme === "dark"
              ? `url(${heroPolyDark})`
              : `url(${heroPolyLight})`,
        }}
      >
        <img
          src={props.theme === "dark" ? hero2 : hero1}
          alt="heroimg"
          className="w-full h-1/2 object-contain -mt-7 md:h-auto md:w-2/5 md:mt-0"
        />
        <div className="text-center md:text-left dark:text-white max-w-xl md:max-w-none">
          <h1 className="text-black text-5xl md:text-7xl font-extrabold dark:text-white">
            Got a doubt?
          </h1>
          <h2 className="text-black text-3xl md:text-5xl font-bold dark:text-white mt-2 md:mt-4">
            Askcode it!
          </h2>
          <p className="text-black text-lg md:text-xl font-medium mt-3 dark:text-white">
            <strong>Jaskaran Singh</strong> (Master, ICPC World Finalist,
            SWE at{" "}
            <img className="h-7 inline-block" src={googleLogo} alt="google" />{" "}
            )
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://askcode.asksenior.in"
            className="inline-flex items-center justify-center border border-black rounded-full py-2.5 px-8 mt-6 text-white bg-black hover:bg-blue2 hover:text-black ease-in-out duration-300 dark:border-white dark:bg-white dark:text-black dark:hover:bg-dark dark:hover:text-white"
          >
            <p className="font-medium">Join our beta version</p>
            <i className="fa-solid fa-chevron-right ml-3"></i>
          </a>
        </div>
      </div>
    </>
  );
};

export default Hero;